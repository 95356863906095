<template>
  <v-main>
    <v-container>
      <h3 class="mb-5 mt-5">Edit Post ID : {{ postData.id }}</h3>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <v-btn @click="goBackToPostList">Back to Post List</v-btn>
      <div v-if="loading">Loading...</div>
      <v-text-field v-model="postData.title" placeholder="Edit Title">
      </v-text-field>
      <div class="mt-5 mb-5">
        <fileInput
          v-model="postData.feature_img_url"
          label="Upload Feature Image"
          @get-url="setFeatureImageUrl($event)"
          :imageUrl="postData.feature_img_url"
        />
      </div>
      <v-text-field v-model="postData.slug" placeholder="Slug"></v-text-field>
      <v-radio-group v-model="postData.language_id" row required>
        <template v-slot:label>
          <div>Language</div>
        </template>
        <v-radio
          v-for="(l, index) in postLanguage"
          :key="index"
          :label="`${l.lang_name}`"
          :value="`${l.id}`"
        ></v-radio>
      </v-radio-group>
      <v-radio-group v-model="postData.post_type_id" row required>
        <template v-slot:label>
          <div>Post Type</div>
        </template>
        <v-radio
          v-for="(t, index) in postType"
          :key="index"
          :label="`${t.name}`"
          :value="`${t.id}`"
        ></v-radio>
      </v-radio-group>
      <PostCategory
        row
        required
        :language_id="postData.language_id"
        :post_category_id="postData.post_category_id"
      ></PostCategory>
      <ckeditor
        v-model="postData.body"
        @get-text="setContents($event)"
        :textContent="postData.body"
      ></ckeditor>
      <v-btn @click="goBackToPostList">Back To Post List</v-btn>|<v-btn
        @click="updatePost"
        >Update</v-btn
      >
      |
      <v-btn @click="deletePost">Delete</v-btn>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import { UPDATE_POST, DELETE_POST } from "../store/actions.type";
import PostCategory from "@/components/PostCategory.vue";
import ckeditor from "@/components/CkEditorComponent.vue";
import fileInput from "@/components/FileUploadComponent.vue";

export default {
  name: "EditPost",
  components: {
    PostCategory,
    ckeditor,
    fileInput,
  },
  data() {
    return {
      postCategory: [],
      postLanguage: [],
      postType: [],
      postData: {
        id: this.$route.params.postID,
        language_id: 0,
        post_type_id: 0,
        post_category_id: 0,
        title: "",
        description: "",
        body: "",
        slug: "",
        feature_img_url: "",
        author: "",
        active: 1,
        sort: 0,
        createdby: "",
        createddate: "",
        updatedby: "",
        updateddate: "",
      },
      loading: false,
      errored: false,
    };
  },
  created() {
    this.postLanguage = JSON.parse(localStorage.getItem("postLanguage"));
    this.postCategory = JSON.parse(localStorage.getItem("postCategory"));
    this.postType = JSON.parse(localStorage.getItem("postType"));
    this.postData = JSON.parse(localStorage.getItem("post"));
  },
  methods: {
    setContents(event) {
      this.postData.body = event;
    },
    setFeatureImageUrl(event) {
      this.postData.feature_img_url = event;
    },
    goBackToPostList() {
      this.$router.push({
        name: "PostList",
      });
    },
    updatePost() {
      var catID = localStorage.getItem("selectedCategoryID");
      if (catID) this.postData.post_category_id = catID;
      if (this.postData.post_category_id != "" && this.postData.title != "") {
        this.$store
          .dispatch(UPDATE_POST, { postData: this.postData })
          .then((data) => {
            this.loading = false;
            this.postData = data;
            this.$swal.fire({
                    title: "Post",
                    text: "Successfully updated!",
                    icon: "success",
                    position: "center",
                    showConfirmButton: false,
                    timer: 1500,
                  });
            this.$router.push({
              name: "PostList",
            });
          })
          .catch(() => {
            this.$swal.fire({
                    title: "Post",
                    text: "Failed to update post!",
                    icon: "danger",
                    position: "center",
                    showConfirmButton: false,
                    timer: 1500,
                  });
            this.loading = false;
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      }
    },
    deletePost() {
      this.$swal
        .fire({
          title: "Are you sure to delete this lesson?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: `No`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            var catID = localStorage.getItem("selectedCategoryID");
            if (catID) this.postData.post_category_id = catID;
            if (
              this.postData.post_category_id != "" &&
              this.postData.title != ""
            ) {
              this.$store
                .dispatch(DELETE_POST, { postId: this.postData.id })
                .then((data) => {
                  this.loading = false;
                  this.postData = data;
                  this.$swal.fire({
                    title: "Post",
                    text: "Successfully deleted!",
                    icon: "success",
                    position: "center",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.$router.push({
                    name: "PostList",
                  });
                })
                .catch(() => {
                  this.$swal.fire("Failed to delete post!", "", "danger");
                  this.loading = false;
                  this.errored = true;
                })
                .finally(() => (this.loading = false));
            }
          }
        });
    }
  },
};
</script>
